import React from 'react'
import img1 from '../images/s1Img.webp'
import '../styles/service.css'

const Service = props => {
  return (
    <div className='service1 flex justify-center items-center font-hind' id ="Service">
        
        <div className='serviceSection1 text-hind text-black'>
        <div className=''>
              <div className='grid grid-cols-1 sm:grid-cols-2'>
                
                <div className='flex justify-center'>
                  <img className='groupImage sm:absolute' src={img1} alt='' /><br/>
                </div>
                
                <div className='flex'>
                  <span className='s1TitleAndSubTitle z-40'><h1 className='s1Title text-primary'>Excellent After Sales Service</h1>
                  <h1 className='s1SubTitle'>Our Maintenance, and Repair Services experts can help reduce 
                        maintenance costs and minimize downtime
                    </h1></span>
                </div>

              </div>
            </div>
        </div>

    </div>
  )
}

export default Service
