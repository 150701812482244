import React from 'react'
import '../styles/contact2.css'
import locationIcon from '../images/location2.png'
import phoneIcon from '../images/phone2.png'
import emailIcon from '../images/email2.png'
import { Link } from 'gatsby'


const Contact2 = props => {
  return (
    
    <div className='contact2 flex justify-center items-center'>
        
        <div className='contactSection2 text-hind text-black font-hind '>
        <div className='c2grid'>
              <div className='grid grid-cols-1 md:grid-cols-2'>
                
                {/* <div className=' flex justify-center items-center'>
                  
                    <div className=''>
                      <h1 className='c2Title1 text-primary'>Reach us</h1>
                      <div className=' flex items-start'>
                        <img className='imageIcon1' src={locationIcon} />
                        <Link to = "/" className='underline'><h1 className='c2SubTitle'>MJM Tower, DOOR NO :42, 86,<br/> 
                          Thurakkal, Manjeri, Kerala 676121</h1></Link>
                      </div>
                      <div className='itemsDiv flex items-start'>
                        <img className='imageIcon1' src={phoneIcon} />
                        <Link to = "/" className='underline'><h1 className='c2SubTitle'>+91 9072 98 4000<br/> 
                          +91 9072 87 8000</h1></Link>
                      </div>
                      <div className='itemsDiv flex items-start'>
                        <img className='imageIcon1' src={emailIcon} />
                        <Link to = "/" className='underline'><h1 className='c2SubTitle'>info@freezeon.in<br/> 
                          freezeonmji@gmail.com</h1></Link>
                      </div>
                    </div>

                    
                  
                </div> */}
                
                <div className='textSide'>
                  <div>
                    <h1 className='c2Title1 text-primary'>Reach us</h1>
                  </div>

                  <div className='flex'>
                    <img className='imageIcon1' src={locationIcon} alt='' />
                    <div>
                      <Link to = "/" className='underline'><h1 className='c2SubTitle'>MJM Tower, DOOR NO :42, 86,<br/> 
                          Thurakkal, Manjeri, Kerala 676121</h1></Link>
                    </div>
                  </div>

                  <div className='flex itemsDiv'>
                    <img className='imageIcon1' src={phoneIcon} alt='' />
                    <div>
                      <Link to = "/" className='underline'><h1 className='c2SubTitle'>+91 9072 98 4000<br/> 
                          +91 9072 87 8000</h1></Link>
                    </div>
                  </div>

                  <div className='flex itemsDiv'>
                    <img className='imageIcon1' src={emailIcon} alt='' />
                    <div>
                      <Link to = "/" className='underline'><h1 className='c2SubTitle'>info@freezeon.in<br/> 
                      freezeonmji@gmail.com</h1></Link>
                    </div>
                  </div>
                </div>

                <div className=' flex justify-center items-center'>
                  
                      <div className='mapDiv flex justify-center'>
                      <iframe title='map' className='map1' src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"></iframe>
                  </div>
                </div>

              </div>
            </div>
        </div>

    </div>
  )
}

export default Contact2
