import React from 'react'
import '../styles/products.css'

// import kerala from '../images/keralaVector.png'
import img1 from '../images/Rect1.webp'
import img2 from '../images/Rect2.webp'
import img3 from '../images/Rect3.webp'
import img4 from '../images/Rect4.webp'
import img5 from '../images/Rect5.webp'
import img6 from '../images/Rect6.webp'
import { Link } from 'gatsby'



const Products = () => {
  return (
    
    <div>
      <div className='products flex justify-center items-center' id ="Products">
        
        <div className='productSection text-hind text-black'>
            <div className='flex justify-center'>
                <h1 className='pTitile text-primary'>Our Range of Products</h1>
            </div>
            <div className=''>
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 text-primary text-center font-hind'>
                
                <div className='flex justify-center '>
                  <span><Link to="/product1/"><img className='pImages' src={img1} alt='' /></Link>
                  <h1 className='pName flex justify-center '>Supermarket<br/> Refrigeration</h1></span>
                </div>
                
                <div className='flex justify-center'>
                  <span><Link to="/product2/"><img className='pImages' src={img6} alt='' /></Link>
                  <h1 className='pName flex justify-center'>Cold Storage<br/> Refrigeration</h1></span>
                </div>
                
                <div className='flex justify-center'>
                  <span><Link to="/product3/"><img className='pImages' src={img3} alt='' /></Link>
                  <h1 className='pName flex justify-center'>Kitchen<br/> Refrigeration</h1></span>
                </div>

                <div className='flex justify-center'>
                  <span><Link to="/product4/"><img className='pImages' src={img2} alt='' /></Link>
                  <h1 className='pName flex justify-center '>Medical<br/> Refrigeration</h1></span>
                </div>
                
                <div className='flex justify-center'>
                  <span><Link to="/product5/"><img className='pImages' src={img4} alt='' /></Link>
                  <h1 className='pName flex justify-center'>Visi Coolers<br/> and Freezers</h1></span>
                </div>
                
                <div className='flex justify-center'>
                  <span><Link to="/product6/"><img className='pImages' src={img5} alt='' /></Link>
                  <h1 className='pName flex justify-center'>Dispenser and<br/> Water Cooler</h1></span>
                </div>

              </div>
            </div>
        </div>
       
      </div>

    </div>
    
  )
}

export default Products
