import React from "react"
import "../styles/global.css"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// import Head from '../components/head'
// import CreateWhatsappChatWidget

import heroImg from "../images/heroImg.png"
import Contact from "../components/contact"
import About from "../components/about"
import Products from "../components/products"
import Service from "../components/service"
import Service2 from "../components/service2"
import Contact2 from "../components/contact2"
// import Helmet from 'react-helmet'

const Index = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          altTag
        }
      }
    }
  `)

  return (
    <div id="Home" className="">
      <Layout>
        <Helmet title={`${data.site.siteMetadata.title} `} />
        <div className="heroBackground flex items-center justify-center w-full h-full z-1  text-white font-hind">
          <div className="heroGrid grid grid-cols-1 sm:grid sm:grid-cols-2 ">
            <div className="heroTextGrid flex flex-col justify-center items-start">
              <h1 className="heroTitle ">Better Freezing Solutions</h1>
              <h1 className="heroSubTitle  text-white">
                We provide all kinds of commercial cooling solutions with 24/7
                maintenance services by experienced work personnel.
              </h1>
            </div>
            <div className="heroImgDiv flex justify-end items-end">
              <img
                className="heroImg sm:absolute relative "
                src={heroImg}
                alt=""
              />
            </div>
          </div>
        </div>

        <About />
        <Products />
        <Service />
        <Service2 />
        <Contact />
        <Contact2 />
      </Layout>
    </div>
  )
}

export default Index
