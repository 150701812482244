import React from 'react'
import '../styles/about.css'

import kerala from '../images/keralaVector.png'

const About = () => {
  return (
    
    <div className='about flex justify-center items-center font-hind'>
        
        <div className='aboutSection text-black'>
            <div className='ImageAndTitle flex items-center justify-center'>
                <img className='keralaImg' src={kerala} alt='' />
                <div>
                    <h1 className='aboutTitle text-primary'>Kerala’s First Ever</h1>
                    <h1 className='aboutSubTitle text-primary'>Exclusive Showroom for Blue Star<br/> Commercial Refrigeration Systems</h1>
                </div>
            </div>
            <h1 className='aboutDescription'>
                Freezeon aims to provide the best commercial refrigeration products and excellent 
                after sales service to all our customers. With over three decades of experience in 
                HVAC and Refrigeration industry, our vast understanding and expertise in the industry 
                makes us your best choice for commercial refrigeration systems.
            </h1>
        </div>

    </div>
    
  )
}

export default About
