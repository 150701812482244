import React from 'react'
import '../styles/service.css'


const Service2 = props => {
  return (
    <div className='service2 flex justify-center items-center'>
        
        <div className='serviceSection2 text-hind text-black font-hind z-40'>
        <div className='s2grid'>
              <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3'>
                
                <div className='flex justify-center'>
                  <span><h1 className='s2Title1 text-primary'>Deep Expertise</h1>
                  <h1 className='s2SubTitle1'>Innovative approaches to repair and preventative maintenance and 
                    operations with technicians with gulf experience.
                  </h1></span>
                </div>
                
                <div className=' flex justify-center  sm:col-span-0 md:col-span-2'>
                  <span className=''><h1 className='s2Title2 text-primary'>Precision Maintenance</h1>
                  <h1 className='s2SubTitle2'>Predictive and diagnostic services help reduce risks, costs, and future 
                    breakdowns. Use of proper tools and equipments to carry out maintenance work.
                  </h1></span>
                </div>

              </div>
            </div>
        </div>

    </div>
  )
}

export default Service2
